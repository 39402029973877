import { SerializedStyles } from '@emotion/core'
import styled from '@emotion/styled'
import { LocationState } from 'history'
import React from 'react'
import { Text, TextStyle } from 'react-native'
import { Link as RRLink, LinkProps as RRLinkProps } from 'react-router-dom'
import { RouteNameType, routePathMap } from '../../navigation/routeMap'

/**
 * https://www.w3schools.com/tags/att_a_target.asp
 * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a#target
 */
enum Target {
  /** the current browsing context. (Default) */
  self = '_self',
  /** usually a new tab, but users can configure browsers to open a new window instead. */
  blank = '_blank',
  /** the parent browsing context of the current one. If no parent, behaves as _self. */
  parent = '_parent',
  /** the topmost browsing context (the "highest" context that’s an ancestor of the current one). If no ancestors, behaves as _self. */
  top = '_top',
}
type LinkProps = Omit<RRLinkProps<LocationState>, 'style' | 'to'> & {
  css?: SerializedStyles
  linkStyle?: React.CSSProperties
  style?: TextStyle
  target?: Target
} & (
    | {
        link?: undefined
        to: RouteNameType
      }
    | {
        link: string
        to?: undefined
      }
  )
const StyledLink = styled<typeof RRLink, { css?: SerializedStyles }>(RRLink)`
  text-decoration: none;
  color: inherit;
  ${({ css }) => css ?? ''}
`

const ExternalLink = styled('a')<{ css?: SerializedStyles }>`
  ${({ css }) => css ?? ''}
`

export const Link: React.FC<LinkProps> = ({ to, link, children, style, linkStyle, ...props }) => {
  if (to)
    return (
      <Text style={style}>
        <StyledLink {...props} to={routePathMap[to]} style={linkStyle}>
          {children}
        </StyledLink>
      </Text>
    )

  console.log({ to, link, props })
  if (__DEV__ && !link.startsWith('https://'))
    throw new Error(`Invalid value for "link" was supplied. got: ${link}`)

  const { component, replace, innerRef, target = Target.blank, ...linkProps } = props
  return (
    <Text style={style}>
      <ExternalLink {...linkProps} href={link} style={linkStyle} target={target}>
        {children}
      </ExternalLink>
    </Text>
  )
}
