import { Colors } from '@a/app/design/colors'
import { css } from '@emotion/core'

export const footerLinkCss = css`
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.4s ease-in-out, color 0.4s ease-in-out;

  &:hover {
    background-color: #efefef;
    color: ${Colors.theme};
  }
`
