import React from 'react'
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native'
import { Colors } from '@a/app/design/colors'

type LoadingViewProps = {
  
}

export const LoadingView: React.FC<LoadingViewProps> = () => {
  return (
    <View style={styles.container}>
      <ActivityIndicator size="large" color={Colors.theme} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
})
