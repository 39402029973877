import { settings } from '@a/web/config/settings'
import React from 'react'
import { StyleSheet, View } from 'react-native'
import { H1 } from '../../atoms/H1'
import { Link } from '../../atoms/Link'
// import { Logo } from '../../atoms/Logo'

type HeaderProps = {}

export const Header: React.FC<HeaderProps> = () => {
  return (
    <View style={styles.container}>
      <View style={styles.inner}>
        <View style={styles.leftBox}>
          {/* <Logo size={36} /> */}
          <H1 style={styles.titleText}>
            <Link to="Home">{settings.appName}</Link>
          </H1>
        </View>
        <View style={styles.rightBox}>
          <Link to="Contact" style={styles.linkText}>
            Contact
          </Link>
          {/* <Link to="Contact" style={styles.linkText}>
            English
          </Link> */}
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    marginVertical: 10,
    marginHorizontal: 35,
    height: 40,
    alignItems: 'center',
    // alignContent: 'space-around',
  },
  inner: {
    maxWidth: 960,
    width: '100%',
    marginHorizontal: 20,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftBox: { flexDirection: 'row', alignItems: 'center' },
  rightBox: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  linkText: {
    fontFamily: 'Helvetica Neue',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: 18,
    lineHeight: 22,
    textAlign: 'center',
    letterSpacing: -0.115765,
    color: '#676767',
    // fontWeight: '400',
    // color: '#ADADAD',
    paddingHorizontal: 10,
  },
  titleText: {
    paddingLeft: 24,
    fontFamily: 'Hiragino Sans',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: 36,
    color: '#02AC7D',
  },
})
