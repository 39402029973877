import React, { useEffect } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { routeMap } from './navigation/routeMap'
import { Routes } from './navigation/Routes'

type RootProps = {}

export const Root: React.FC<RootProps> = () => {
  useEffect(() => {
    setTimeout(() => {
      Promise.all(Object.values(routeMap).map((route) => route.page.load()))
        .then(() => console.log('loaded!!'))
        .catch()
    }, 1500)
  }, [])
  return (
    <Router>
      <Routes />
    </Router>
  )
}
