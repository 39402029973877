import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { settings } from '../../../config/settings'
import { Link } from '../../atoms/Link'

type CopyrightProps = {}

export const Copyright: React.FC<CopyrightProps> = () => {
  return (
    <View style={styles.box}>
      <Link to="Home">
        <Text style={styles.linkT}>© {settings.companyName}</Text>
      </Link>
    </View>
  )
}

const styles = StyleSheet.create({
  box: {
    backgroundColor: '#272727',
    paddingTop: 24,
    paddingBottom: 24,
    alignItems: 'center',
  },
  linkT: {
    textAlign: 'center',
    color: 'white',
    fontSize: 16,
    // textDecoration: 'none',
  },
})
