export const mediaSize = {
  phoneUp: 600,
  tabletPortraitUp: 900,
  tabletLandscapeUp: 1200,
  desktopUp: 1800,
}
export const mediaQuery = {
  forPhoneOnly: '@media (max-width: 599px)',
  forMobile: '@media (max-width: 899.9px)',
  forTabletPortraitUp: '@media (min-width: 600px)',
  forTabletLandscapeUp: '@media (min-width: 900px)',
  forDesktopUp: '@media (min-width: 1200px)',
  forBigDesktopUp: '@media (min-width: 1800px)',
}
export const fonts = {
  default: 'Mplus 1p,Open Sans,Roboto,Helvetica,sans-serif',
  // default: '"Mplus 1p","Open Sans",Roboto,Helvetica,sans-serif',
}

export const breakpoints = {
  tablet: 600,
  desktop: 1024,
} as const
type BpName = keyof typeof breakpoints
type MediaQueryMap = { [key in BpName]: string }
export const mediaQueryMap: MediaQueryMap = Object.entries(breakpoints).reduce<MediaQueryMap>(
  (pv, cv) => {
    pv[cv[0] as BpName] = `@media (min-width: ${cv[1]}px)`
    return pv
  },
  {} as MediaQueryMap,
)
