
import React from 'react'
import { Copyright } from './Copyright'
import { FooterNav } from './FooterNav'

type FooterProps = {}

export const Footer: React.FC<FooterProps> = () => {
  return (
    <footer>
      <FooterNav />
      <Copyright />
    </footer>
  )
}
