import React from 'react'
import { StyleSheet, Text, TextStyle } from 'react-native'

type H1Props = {
  style?: TextStyle
}

export const H1: React.FC<H1Props> = ({ style = styles.text, children }) => {
  return (
    // <h1>
    <Text style={style}>{children}</Text>
    // </h1>
  )
}

const styles = StyleSheet.create({
  text: {
    flex: 1,
  },
})
