import { lazy } from 'react'

type LazyFactory<T extends React.ComponentType> = () => Promise<{ default: T }>
type LazyLoadableComponent<T extends React.ComponentType> = React.LazyExoticComponent<T> & {
  load: () => void
}

function genLazyLoadableComp<T extends React.ComponentType>(
  factory: LazyFactory<T>,
): LazyLoadableComponent<T> {
  const Comp = lazy(factory)
  let factoryPromise: Promise<void> | undefined

  // @ts-ignore
  Comp.load = () => {
    if (factoryPromise) return factoryPromise

    factoryPromise = factory().then(() => {})
    return factoryPromise
  }
  // @ts-ignore
  return Comp as LazyLoadableComponent<T>
}

/* eslint-disable @typescript-eslint/naming-convention */
const legalRouteMap = {
  Terms: {
    // page: TermsPage,
    page: genLazyLoadableComp(() => import('@a/web/pages/terms')),
    path: '/terms',
  },
  Privacy: {
    // page: PrivacyPage,
    page: genLazyLoadableComp(() => import('@a/web/pages/privacy')),
    path: '/privacy',
  },
} as const
export const routeMap = {
  Contact: {
    // page: ContactPage,
    page: genLazyLoadableComp(() => import('@a/web/pages/contact')),
    path: '/contact',
  },
  Home: {
    // page: HomePage,
    page: genLazyLoadableComp(() => import('@a/web/pages/index')),
    path: '/',
  },
  ContactThanks: {
    // page: HomePage,
    page: genLazyLoadableComp(() => import('@a/web/pages/contact/thanks')),
    path: '/contact/thanks',
  },
  ...legalRouteMap,
} as const

export const routeMapEntries = Object.entries(routeMap)
export const routePathMap = routeMapEntries.reduce<{ [key in RouteNameType]: string }>(
  (pv, [key, val]) => {
    pv[key] = val.path
    return pv
  },
  {} as any, // eslint-disable-line @typescript-eslint/no-explicit-any
)

export type RouteNameType = keyof typeof routeMap
