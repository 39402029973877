import React from 'react'
import ReactGA from 'react-ga'
import { Helmet } from 'react-helmet'
import { Route, RouteComponentProps, Switch, useLocation } from 'react-router-dom'
import { Footer } from '../comps/common/Footer/Footer'
import { Header } from '../comps/common/Header/Header'
import { LoadingView } from '../comps/navigation/LoadingView'
import { DEFAULT_LOCALE, settings } from '../config/settings'
// import { RouteTransition } from '../comps/transition/RouteTransition'
import NotFound from '../pages/404'
import { routeMapEntries } from './routeMap'

type RoutesProps = {}

export const Routes: React.FC<RoutesProps> = () => {
  const location = useLocation()

  console.log('[Routes] location.pathname', location.pathname)
  ReactGA.pageview(location.pathname + window.location.search)
  return (
    <>
      <Helmet>
        <html lang={DEFAULT_LOCALE} />
        <link rel="canonical" href={`${settings.url}${location.pathname}`} />
      </Helmet>
      <Header />
      <React.Suspense fallback={fallbackElement}>
        {/* <RouteTransition location={location}> */}
        <Switch location={location}>
          <Route path="/l/:locale" component={AllRoutes} />

          {routeMapEntries.map(([, { path, page: Page }]) => (
            <Route exact path={path} render={() => <Page />} key={path} />
          ))}

          <Route component={NotFound} />
        </Switch>
        {/* </RouteTransition> */}
      </React.Suspense>
      <Footer />
    </>
  )
}

const AllRoutes: React.FC<RouteComponentProps<{ locale: string }>> = ({ match }) => {
  const { locale } = match.params
  console.log({ locale })
  // const {match, location} = props
  // console.log(props);
  // console.log("location", location);
  // console.log("match", match);

  return (
    <>
      <Helmet>
        <html lang={locale} />
      </Helmet>

      {routeMapEntries.map(([, { path, page: Page }]) => (
        <Route exact path={`${match.url}${path}`} render={() => <Page />} key={path} />
      ))}
    </>
  )
}

const fallbackElement = <LoadingView />
