/**
 * R: 2   / 255
 * G: 172 / 255
 * B: 125 / 255
 */

export const Colors = {
  theme: '#02AC7D',
  splash: '#02AC7D',
  splashText: '#FFF',
  green: '#02AC7D',
  greenDark: '#0A9D75',
  // greenDark: '#0F5542',
  greenLight: '#EFF5F3',
  border: {
    light: '#B5B5B5',
  },
  blueWater: '#62E3FF',
  black: '#000000',
  white: '#FFFFFF',
} as const

export const Theme = {
  default: {
    bgColor: 'white',
    headerColor: Colors.greenDark,
    headerText: 'white',
    settingHeaderText: '#191919',
    settingBgColor: '#f1f1f1',
    setting: {
      itemBgColor: 'white',
      itemDescText: '#808080',
      itemTitle: 'rgba(0,0,0,0.87)',
      itemSubTitle: 'rgba(0,0,0,0.5)',
      itemInfoText: '#AEAEAE',
      itemCaret: 'rgba(0,0,0,0.5)',
    },
    form: {
      placeholder: '#AEAEAE',
      selectionColor: Colors.theme,
    },
    list: {
      text: {
        main: '#404040',
        sub: '#808080',
      },
      separator: {
        light: '#d1d1d1',
      },
    },
  },
}
