import { footerLinkCss } from '@a/web/design/css'
import { DEFAULT_PADDING } from '@a/web/design/design'
import { RouteNameType } from '@a/web/navigation/routeMap'
import styled from '@emotion/styled'
import { mediaQueryMap } from '@plusuno/react-ui-legal/src/config/design'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Link } from '../../atoms/Link'

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  background-color: rgb(249, 249, 249);
  padding: 0 ${DEFAULT_PADDING}px ${DEFAULT_PADDING}px;

  ${mediaQueryMap.tablet} {
    flex-direction: row;
  }
`

const footerNavSections: FooterNavSection[] = [
  {
    title: 'Company',
    links: [
      { label: '会社情報', link: 'https://www.plusuno.jp/company' },
      { label: 'お問い合わせ', to: 'Contact' },
    ],
  },
  {
    title: 'Legal',
    links: [
      { label: '利用規約', to: 'Terms' },
      { label: '個人情報保護方針', to: 'Privacy' },
    ],
  },
]

type LinkType = {
  label: string
  title?: string
} & (
  | { link?: undefined; to: RouteNameType }
  | {
      /**
       * Must start with "https://"
       */
      link: string
      to?: undefined
    }
)
type FooterNavSection = {
  links: LinkType[]
  title: string
}

export type FooterNavProps = {
  sections?: FooterNavSection[]
}

const FooterNavFC: React.FC<FooterNavProps> = ({ sections = footerNavSections }) => {
  // const isMobileOrTabletDevice = useMediaQuery({
  //   maxDeviceWidth: breakpoints.tablet,
  //   maxWidth: breakpoints.tablet,
  // })
  return (
    <Wrapper
    // style={isMobileOrTabletDevice ? styles.wrapper : [styles.wrapper, { flexDirection: 'row' }]}
    >
      {sections.map((section) => (
        <FooterNavSection key={section.title} {...section} />
      ))}
    </Wrapper>
  )
}

export const FooterNav = React.memo(FooterNavFC)

const FooterNavSection: React.FC<FooterNavSection> = ({ title, links }) => {
  return (
    <View style={styles.section}>
      <View style={styles.title}>
        <Text style={styles.titleT}>{title}</Text>
      </View>
      <View style={styles.links}>
        {links.map(({ link, label, to, title: title_ }) => (
          <Link
            title={title_ ?? label}
            to={to}
            // FIXME: tsc get crazy and shows error
            link={(link as unknown) as undefined}
            css={footerLinkCss}
            style={styles.linkT}
            key={label}
          >
            {label}
          </Link>
        ))}
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'column',
    backgroundColor: 'rgb(249,249,249)',
    paddingHorizontal: DEFAULT_PADDING,
    paddingVertical: DEFAULT_PADDING,
  },
  section: {
    minWidth: 260,
    flex: 1,
    paddingTop: DEFAULT_PADDING,
  },
  title: { marginBottom: 10 },
  titleT: { color: 'rgba(0,0,0,0.88)', fontWeight: '600', fontSize: 14, lineHeight: 14 },
  links: { alignItems: 'flex-start' },
  linkT: { color: 'rgba(0,0,0,0.5)', fontWeight: '500', fontSize: 14, lineHeight: 14, marginTop: 9 },
})
