import './design/css/reset.css'

import { init } from '@sentry/browser'

init({
  dsn: 'https://4f411d91d33b4a7db08d0d7cbcbded31@sentry.io/2737122',
  enabled: !__DEV__,
  debug: __DEV__,
  environment: __DEV__ ? 'dev' : 'prod',
  // release: "identifier-1.0.0"
})
// import TagManager from 'react-gtm-module'
// import { googleTagManager } from './config/settings'

// const tagManagerArgs = {
//   gtmId: googleTagManager.id,
// }

// TagManager.initialize(tagManagerArgs)

import ReactGA from 'react-ga'
import { googleAnalytics } from './config/settings'
ReactGA.initialize(googleAnalytics.id)

export { Root as App } from './Root'
