export const DEFAULT_LOCALE = 'ja'
export const settings = {
  companyName: 'Plusuno Inc.',
  appName: 'BabySmile',
  /**
   * https://github.com/search?q=org%3Aexpo+WEB_PUBLIC_URL&type=Code
   * https://github.com/expo/expo-cli/blob/4efa3056066f031ea482a519d9ed2ea58f393d7a/packages/webpack-config/src/env/paths.ts#L108-L121
   */
  url: process.env.WEB_PUBLIC_URL ?? '',
} as const

export const storeUrl = {
  android: {
    googlePlay: 'https://play.google.com/store/apps/details?id=jp.plusuno.babyapp',
  },
} as const

export const googleTagManager = {
  id: 'GTM-KWRH5M2',
} as const

export const googleAnalytics = {
  id: __DEV__ ? 'UA-96179613-3' : 'UA-96179613-2',
} as const
